// ----------------------------------------------------------------------

import { identifier } from 'stylis';

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';
const ROOTS_TEACHING = '/teaching';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_APP = {
  root: '/',
  teaching: {
    root: ROOTS_TEACHING,
    home: path(ROOTS_TEACHING, '/home'),
    classroom: {
      root: path(ROOTS_TEACHING, '/classroom'),
      new: path(ROOTS_TEACHING, '/classroom/new'),
      edit: (classroomId) => path(ROOTS_TEACHING, `/classroom/${classroomId}/edit`),
      view: (classroomId) => path(ROOTS_TEACHING, `/classroom/${classroomId}/view`),
    },
    // newClassRoom: path(ROOTS_TEACHING, '/new-classroom'),
  },
  user: {
    root: ROOTS_USER,
    profile: path(ROOTS_USER, '/profile'),
  },
};
