import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { collection, addDoc, onSnapshot, query, where, updateDoc, getDoc, doc } from 'firebase/firestore';
// utils
import axios from '../../utils/axios';
import { firestore } from '../../utils/firebase';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  classrooms: [],
  ownClassRooms: [],
  classroom: null,
  sortBy: null,
  filters: {},
};

const slice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE CLASS ROOM
    createClassRoomSuccess(state, action) {
      const newClassRoom = action.payload;
      state.isLoading = false;
      state.classrooms = [...state.classrooms, newClassRoom];
    },

    // UPDATE CLASSROOM
    updateClassRoomSuccess(state, action) {
      const classroom = action.payload;
      const updateClassRoom = state.classrooms.map((_classroom) => {
        if (_classroom.id === classroom.id) {
          return classroom;
        }
        return _classroom;
      });

      state.isLoading = false;
      state.classrooms = updateClassRoom;
    },

    // GET CLASSROOMS
    getClassRoomsSuccess(state, action) {
      state.isLoading = false;
      state.classrooms = action.payload;
    },

    // GET OWN CLASSROOMS
    getOwnClassRoomsSuccess(state, action) {
      state.isLoading = false;
      state.ownClassRooms = action.payload;
    },

    // GET CLASSROOM
    getClassRoomSuccess(state, action) {
      state.isLoading = false;
      state.classroom = action.payload;
    },

    //  SORT & FILTER CLASSROOMS
    sortByClassRooms(state, action) {
      state.sortBy = action.payload;
    },

    filterClassRooms(state, action) {},
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByClassRooms, filterClassRooms } = slice.actions;

// ----------------------------------------------------------------------

export function getOwnClassrooms(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(user);
      if (!user) {
        throw new Error('User is not logged in');
      }
      // Removed where for testing
      // where('createdBy', '==', user.id))
      const unsubscribe = await onSnapshot(query(collection(firestore, 'classrooms')), (snapshot) => {
        const classrooms = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });

        dispatch(slice.actions.getOwnClassRoomsSuccess(classrooms));
      });

      // console.log(classrooms);
      // dispatch(slice.actions.getClassRoomsSuccess(classrooms.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getClassroom(user, classroomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const unsubscribe = await onSnapshot(query(doc(firestore, `classrooms/${classroomId}`)), (snapshot) => {
        const classroom = snapshot.data();
        classroom.id = snapshot.id;
        dispatch(slice.actions.getClassRoomSuccess(classroom));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function createClassRoom(classroom, user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const { user } = useAuth();
      await addDoc(collection(firestore, 'classrooms'), {
        ...classroom,
        createdBy: user.id,
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateClassRoom(currentClassRoom, updateClassRoom, user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await updateDoc(doc(firestore, `classrooms/${currentClassRoom.id}`), updateClassRoom);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function deleteClassRoom(classroomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/inventory/items/${classroomId}`);
      if (response.data.id) dispatch(slice.actions.updateClassRoomSuccess(response.data));
      else dispatch(slice.actions.hasError(response.data.message || 'Error deleting classroom'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function deleteClassRoomImage(imageId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/inventory/item-image/${imageId}`);
      if (!response.data.id)
        dispatch(slice.actions.hasError(response.data.message || 'Error deleting classroom image'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
