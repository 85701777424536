import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { collection, addDoc, onSnapshot, query, where } from 'firebase/firestore';
// utils
import axios from '../../utils/axios';
import { firestore } from '../../utils/firebase';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  sortBy: null,
  filters: {
    id: '',
    name: '',
    email: '',
    email_verified_at: '',
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      const newUser = action.payload;
      state.isLoading = false;
      state.users = [...state.users, newUser];
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      const user = action.payload;
      const updateUser = state.users.map((_user) => {
        if (_user.id === user.id) {
          return user;
        }
        return _user;
      });

      state.isLoading = false;
      state.users = updateUser;
    },

    // GET LOCATIONs
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET LOCATION
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    //  SORT & FILTER LOCATIONS
    sortByUsers(state, action) {
      state.sortBy = action.payload;
    },

    filterUsers(state, action) {
      state.filters.id = action.payload.id;
      state.filters.name = action.payload.name;
      state.filters.email = action.payload.email;
      state.filters.email_verified_at = action.payload.email_verified_at;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByUsers, filterUsers } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const unsubscribe = await onSnapshot(collection(firestore, 'users'), (snapshot) => {
        const users = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
        dispatch(slice.actions.getUsersSuccess(users));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/users/', {
        params: { id },
      });
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
