// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  home: <Iconify icon={'eva:home-outline'} width={32} height={32} />,
  add: <Iconify icon={'eva:plus-outline'} width={32} height={32} />,
};

const navConfig = [
  // TEACHING
  // ----------------------------------------------------------------------
  {
    subheader: 'teaching',
    items: [
      { title: 'Home', path: '/teaching/home', icon: ICONS.home },
      // { title: 'Classes', path: '/teaching/classes', icon: ICONS.subject },

      { title: 'New Class', path: '/teaching/classroom/new', icon: ICONS.add, type: 'button' },
    ],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: '/dashboard/user',
        icon: ICONS.user,
        children: [
          { title: 'Four', path: '/dashboard/user/four' },
          { title: 'Five', path: '/dashboard/user/five' },
          { title: 'Six', path: '/dashboard/user/six' },
        ],
      },
    ],
  },
];

export default navConfig;
