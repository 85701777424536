import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

import { FIREBASE_API } from '../config';

// Initialize Firebase
const app = initializeApp(FIREBASE_API);

// Initialize Cloud Firestore and get a reference to the service
const firestore = getFirestore(app);

export { firestore };
